import React, { ReactElement, useCallback, useMemo, useState } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import {
  endorsementDataS3Upload,
  endorsementSubmissionForSite,
} from '@/library/apiServices/scheduleWOServices';
import Button from '@/library/components/atoms/Button';
import HeaderText from '@/library/components/atoms/HeaderText';
import CsvDownloader from '@/library/components/molecules/CSVDownloader';
import ConfirmationModal from '@/library/components/molecules/ConfirmationModal';
import Tooltip from '@/library/components/molecules/Tooltip';
import EditWOSessionManager from '@/library/components/organisms/EditWOSessionManager';
import { metricNames } from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import {
  fetchRecommendationGenerationStatusForSite,
  resetFilteredWORecommendationsTableList,
  startActivityTrackingInActiveSession,
  startWOEditSession,
  stopWOEditSession,
  updateScheduleWOMessageBanner,
  updateSelectedWOs,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';
import { formatRecommendationGenerationData } from '@/library/storeSlices/scheduleWOData/scheduleWODataUtils';
import { convertMillisecondsToMinutes } from '@/library/utils/dateUtils';
import { formUiStringWithParameter } from '@/library/utils/stringUtils';
import {
  formatWOsExcelSheetData,
  getOverwriteWOsMetricsData,
  initialiseWorkOrdersData,
  recordSWOEvent,
} from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersUtils';

import './tableHeaderStyles.scss';

function TableHeader(): ReactElement {
  const [isSubmittingUserEndorsements, setIsSubmittingUserEndorsements] =
    useState(false);

  const dispatch = useAppDispatch();
  const {
    isAnyEditMade,
    isEditSessionActive,
    woRecommendationsMasterDataWithoutUserEdits,
    woRecommendationsMasterDataWithUserEdits,
    selectedWOs,
    woRecommendationsFilteredTableDataWithUserEdits,
    workOrdersTableErrors,
    recommendationsMetadata,
    shiftLaborRollupData,
  } = useAppSelector(state => state.scheduleWODataReducer);

  const { activeSite, ottoAppConfig } = useAppSelector(
    state => state.masterDataReducer,
  );

  const [undoConfirmationModalVisible, setUndoConfirmationModalVisible] =
    useState({ isModalVisible: false, isNewRunAvailable: false });

  const [refreshConfirmationModalVisible, setRefreshConfirmationModalVisible] =
    useState(false);

  const [
    endorsementConfirmationModalVisible,
    setEndorsementConfirmationModalVisible,
  ] = useState({
    isModalVisible: false,
    isNewRunAvailable: false,
    isNewRunInProgress: false,
  });

  const [isUndoInProgress, setIsUndoInProgress] = useState(false);

  const undoUserEdits = (): unknown =>
    dispatch(resetFilteredWORecommendationsTableList(true));

  const onConfirmUndo = useCallback((skipEvent?: boolean) => {
    !skipEvent &&
      recordSWOEvent(
        undoConfirmationModalVisible.isNewRunAvailable
          ? metricNames.swo_undoToPreviousRun
          : metricNames.swo_undo,
      );
    undoUserEdits();
    setUndoConfirmationModalVisible({
      isModalVisible: false,
      isNewRunAvailable: false,
    });
  }, []);

  const onLoadNewRun = useCallback(endSessionMode => {
    onConfirmUndo(true);
    onDismissEndorsementModal();
    dispatch(stopWOEditSession(endSessionMode));
    initialiseWorkOrdersData(dispatch);
  }, []);

  const onDismissUndoModal = useCallback(() => {
    setUndoConfirmationModalVisible({
      isModalVisible: false,
      isNewRunAvailable: false,
    });
  }, []);

  const onDismissRefreshModal = useCallback(() => {
    setRefreshConfirmationModalVisible(false);
  }, []);

  const onDismissEndorsementModal = useCallback(() => {
    setEndorsementConfirmationModalVisible({
      isModalVisible: false,
      isNewRunAvailable: false,
      isNewRunInProgress: false,
    });
    setIsSubmittingUserEndorsements(false);
  }, []);

  const displayUndoModal = (): void => {
    setIsUndoInProgress(true);
    dispatch(fetchRecommendationGenerationStatusForSite())
      .then(data => {
        const { isNewRunAvailable } = formatRecommendationGenerationData(
          data.payload,
          recommendationsMetadata.modelRunDate,
        );
        setUndoConfirmationModalVisible({
          isModalVisible: true,
          isNewRunAvailable,
        });
      })
      .catch(() => {
        setUndoConfirmationModalVisible({
          isModalVisible: true,
          isNewRunAvailable: false,
        });
      })
      .finally(() => {
        setIsUndoInProgress(false);
      });
  };

  const isEndorseButtonDisabled = useMemo(
    () =>
      !isEditSessionActive ||
      (selectedWOs.length > 0
        ? selectedWOs.some(
            wo => workOrdersTableErrors[wo.workOrderID].count > 0,
          )
        : !woRecommendationsMasterDataWithUserEdits.length ||
          woRecommendationsMasterDataWithUserEdits.some(
            wo => workOrdersTableErrors[wo.workOrderID].count > 0,
          )),
    [
      isEditSessionActive,
      selectedWOs,
      workOrdersTableErrors,
      woRecommendationsMasterDataWithUserEdits,
    ],
  );

  const endorseWorkOrders = (): void => {
    setIsSubmittingUserEndorsements(true);
    dispatch(fetchRecommendationGenerationStatusForSite())
      .then(data => {
        const { isNewRunAvailable, isNewRunInProgress } =
          formatRecommendationGenerationData(
            data.payload,
            recommendationsMetadata.modelRunDate,
          );
        setEndorsementConfirmationModalVisible({
          isModalVisible: true,
          isNewRunAvailable,
          isNewRunInProgress,
        });
      })
      .catch(() => {
        setIsSubmittingUserEndorsements(false);
      });
  };

  const onConfirmEndorsement = (): void => {
    setEndorsementConfirmationModalVisible({
      isModalVisible: false,
      isNewRunAvailable: false,
      isNewRunInProgress: false,
    });
    dispatch(updateScheduleWOMessageBanner('endorsementSuccessful'));
    dispatch(updateScheduleWOMessageBanner('endorsementFailed'));
    dispatch(
      updateScheduleWOMessageBanner({
        type: 'info',
        loading: true,
        content: uiStrings.woEndorsementPending,
        id: 'endorsementPending',
        onDismiss: () =>
          dispatch(updateScheduleWOMessageBanner('endorsementPending')),
      }),
    );
    endorsementDataS3Upload()
      .then(payload => {
        endorsementSubmissionForSite(payload.endorsementTimestamp)
          .then(() => {
            try {
              const overwriteWOsMetricsData = getOverwriteWOsMetricsData(
                selectedWOs.length
                  ? selectedWOs
                  : woRecommendationsMasterDataWithUserEdits,
              );
              recordSWOEvent(metricNames.swo_endorsementSuccessful, {
                endorsedWOsCount:
                  selectedWOs.length ||
                  woRecommendationsMasterDataWithUserEdits.length,
                endorsementTimestamp: payload.endorsementTimestamp,
                ...overwriteWOsMetricsData,
              });
            } catch (e) {
              recordSWOEvent(metricNames.swo_endorsementSuccessful, {
                endorsedWOsCount:
                  selectedWOs.length ||
                  woRecommendationsMasterDataWithUserEdits.length,
                endorsementTimestamp: payload.endorsementTimestamp,
              });
            }

            initialiseWorkOrdersData(dispatch);
            dispatch(startWOEditSession());
            dispatch(startActivityTrackingInActiveSession());
            dispatch(
              updateScheduleWOMessageBanner({
                type: 'success',
                content: formUiStringWithParameter(
                  uiStrings.woEndorsedSuccessfully,
                  { woCount: payload.formattedWOsData.endorsedWOs.length },
                ),
                dismissible: true,
                id: 'endorsementSuccessful',
                onDismiss: () =>
                  dispatch(
                    updateScheduleWOMessageBanner('endorsementSuccessful'),
                  ),
              }),
            );
            setIsSubmittingUserEndorsements(false);
          })
          .catch(handleWorkOrdersEndorsementError);
      })
      .catch(handleWorkOrdersEndorsementError);
  };

  const handleWorkOrdersEndorsementError = (e): void => {
    recordSWOEvent(metricNames.swo_endorsementFailed, {
      endorsedWOsCount:
        selectedWOs.length || woRecommendationsMasterDataWithUserEdits.length,
    });
    setIsSubmittingUserEndorsements(false);
    dispatch(updateScheduleWOMessageBanner('endorsementPending'));

    // ToDo: Shift to using a errorCode instead of message, once implemented on backend
    const LOCK_EXPIRED_IDENTIFIER_STRING =
      'is not having an active lock to endorse';

    dispatch(
      updateScheduleWOMessageBanner({
        type: 'error',
        content: (
          <div className="endorsement-failed-container">
            {e?.response?.data?.message.includes(
              LOCK_EXPIRED_IDENTIFIER_STRING,
            ) ? (
              <span>
                {formUiStringWithParameter(
                  uiStrings.editingSessionExpiredOnEndorsement,
                  {
                    maximumIdleDuration: convertMillisecondsToMinutes(
                      ottoAppConfig.lockSessionEndTimeout,
                    ),
                  },
                )}
              </span>
            ) : (
              <>
                <span>{uiStrings.couldNotEndorseWorkOrders}</span>
                <Button onClick={endorseWorkOrders}>{uiStrings.retry}</Button>
              </>
            )}
          </div>
        ),
        id: 'endorsementFailed',
        onDismiss: () =>
          dispatch(updateScheduleWOMessageBanner('endorsementFailed')),
      }),
    );
  };

  const endorseButton = (
    <Button
      variant="primary"
      disabled={isEndorseButtonDisabled}
      onClick={endorseWorkOrders}
      loading={isSubmittingUserEndorsements}
    >
      {uiStrings.endorse} (
      {selectedWOs.length || woRecommendationsMasterDataWithoutUserEdits.length}
      /{woRecommendationsMasterDataWithoutUserEdits.length})
    </Button>
  );

  const onSelectAllPages = (): void => {
    dispatch(
      updateSelectedWOs(woRecommendationsFilteredTableDataWithUserEdits),
    );
  };

  const onUnselectAllPages = (): void => {
    dispatch(updateSelectedWOs([]));
  };

  const onClickRefresh = (): void => {
    if (isAnyEditMade) {
      setRefreshConfirmationModalVisible(true);
    } else {
      onConfirmRefresh();
    }
  };

  const onConfirmRefresh = (): void => {
    recordSWOEvent(metricNames.swo_refreshTable);
    onDismissRefreshModal();
    initialiseWorkOrdersData(dispatch);
    if (isEditSessionActive) {
      dispatch(startWOEditSession());
      dispatch(startActivityTrackingInActiveSession());
    }
  };

  const downloadExcelData = useMemo(
    () =>
      formatWOsExcelSheetData(
        woRecommendationsMasterDataWithUserEdits,
        shiftLaborRollupData,
      ),
    [woRecommendationsMasterDataWithUserEdits, shiftLaborRollupData],
  );

  return (
    <div className="table-header">
      <ConfirmationModal
        visible={refreshConfirmationModalVisible}
        header={uiStrings.refreshConfirmationHeading}
        onDismiss={onDismissRefreshModal}
        actionButtons={
          <>
            <Button onClick={onDismissRefreshModal}>{uiStrings.close}</Button>
            <Button
              variant="primary"
              onClick={onConfirmRefresh}
            >
              {uiStrings.refresh}
            </Button>
          </>
        }
      >
        <p>{uiStrings.refreshConfirmationDescription}</p>
      </ConfirmationModal>
      <ConfirmationModal
        visible={undoConfirmationModalVisible.isModalVisible}
        header={uiStrings.undoConfirmationHeading}
        onDismiss={onDismissUndoModal}
        actionButtons={
          <>
            <Button onClick={onDismissUndoModal}>{uiStrings.close}</Button>
            <Button
              variant="primary"
              onClick={() => onConfirmUndo()}
            >
              {undoConfirmationModalVisible.isNewRunAvailable
                ? uiStrings.revertToPreviousRun
                : uiStrings.confirmUndo}
            </Button>
            {undoConfirmationModalVisible.isNewRunAvailable && (
              <Button
                variant="primary"
                onClick={() =>
                  onLoadNewRun(metricNames.swo_loadNewRunOnUndoClick)
                }
              >
                {uiStrings.loadNewRun}
              </Button>
            )}
          </>
        }
      >
        {undoConfirmationModalVisible.isNewRunAvailable ? (
          <>
            <p>{uiStrings.newRunAvailableConfirmUndo}</p>
            <br />
            <p>{uiStrings.bothOptionsWillRevertChanges}</p>
          </>
        ) : (
          <>
            <p>{uiStrings.undoConfirmationDescription}</p>
            <br />
            <p>{uiStrings.cannotUndoThis}</p>
          </>
        )}
      </ConfirmationModal>
      <ConfirmationModal
        visible={endorsementConfirmationModalVisible.isModalVisible}
        header={
          endorsementConfirmationModalVisible.isNewRunInProgress
            ? uiStrings.endorseConfirmationNewRunInProgressHeading
            : uiStrings.endorseConfirmationHeading
        }
        onDismiss={onDismissEndorsementModal}
        actionButtons={
          <>
            <Button onClick={onDismissEndorsementModal}>
              {uiStrings.close}
            </Button>
            {endorsementConfirmationModalVisible.isNewRunAvailable && (
              <Button
                variant="primary"
                onClick={() =>
                  onLoadNewRun(metricNames.swo_loadNewRunOnEndorseClick)
                }
              >
                {uiStrings.loadNewRun}
              </Button>
            )}
            <Button
              variant="primary"
              onClick={onConfirmEndorsement}
            >
              {uiStrings.confirmEndorsement}
            </Button>
          </>
        }
      >
        <p>
          {formUiStringWithParameter(
            endorsementConfirmationModalVisible.isNewRunAvailable
              ? uiStrings.newRunAvailableConfirmEndorsement
              : endorsementConfirmationModalVisible.isNewRunInProgress
              ? uiStrings.newRunInProgressConfirmEndorsement
              : uiStrings.endorsementConfirmationMessage,
            {
              count:
                selectedWOs.length ||
                woRecommendationsMasterDataWithoutUserEdits.length,
            },
          )}
        </p>
        <br />
        <p>{uiStrings.cannotUndoThis}</p>
      </ConfirmationModal>
      <div className="heading-section">
        <HeaderText>{uiStrings.scheduleWorkOrder}</HeaderText>
        <div className="sub-heading">
          <Tooltip
            content={`${uiStrings.filteredWOs} (${woRecommendationsFilteredTableDataWithUserEdits.length}) / ${uiStrings.totalWOs} (${woRecommendationsMasterDataWithoutUserEdits.length})`}
            position="top"
          >
            <p className="filtered-total-count">
              ( {woRecommendationsFilteredTableDataWithUserEdits.length} /{' '}
              {woRecommendationsMasterDataWithoutUserEdits.length} )
            </p>
          </Tooltip>
        </div>
        <div>
          <Button
            onClick={onSelectAllPages}
            variant="link"
            disabled={!isEditSessionActive}
          >
            {uiStrings.selectAllPages}
          </Button>
          <span>|</span>
          <Button
            onClick={onUnselectAllPages}
            variant="link"
            disabled={!isEditSessionActive}
          >
            {uiStrings.unSelectAllPages}
          </Button>
        </div>
      </div>
      <div className="header-cta-section">
        <Button
          iconName="refresh"
          onClick={onClickRefresh}
          disabled={isSubmittingUserEndorsements || isUndoInProgress}
          loading={isSubmittingUserEndorsements || isUndoInProgress}
        />
        <Button
          iconName="undo"
          onClick={displayUndoModal}
          disabled={!isAnyEditMade}
          loading={isSubmittingUserEndorsements || isUndoInProgress}
        >
          {uiStrings.undoChanges}
        </Button>
        <CsvDownloader
          iconName="download"
          data={downloadExcelData}
          filename={`OttoRecommendations_${activeSite?.siteId}_${recommendationsMetadata.modelRunDate}`}
          onClick={() => recordSWOEvent(metricNames.swo_downloadExcel)}
          disabled={!woRecommendationsMasterDataWithoutUserEdits.length}
        >
          {uiStrings.downloadExcel}
        </CsvDownloader>
        {isEndorseButtonDisabled ? (
          <Tooltip
            content={
              isEditSessionActive ? (
                selectedWOs.length ? (
                  uiStrings.resolveErrorsForSelectedWOs
                ) : (
                  uiStrings.resolveErrorsForAllWOs
                )
              ) : (
                <>
                  <p className="start-session-instructions-text">
                    {uiStrings.startSessionInstructions}
                  </p>
                  <EditWOSessionManager buttonVariant="normal" />
                </>
              )
            }
            position="top"
            triggerType="custom"
          >
            {endorseButton}
          </Tooltip>
        ) : (
          endorseButton
        )}
      </div>
    </div>
  );
}

export default TableHeader;
